export const eventsData = [
    {
        "id": 1,
        "eventType": "Webinar",
        "eventName": "ZetScore Virtual Showcase",
        "eventDesc": "ZetScore Live Demonstration event provides an in-depth look into the comprehensive suite of services offered. Join us to explore how ZetScore revolutionizes Human Performance Management with its array of services including Peer Review, Assessment Review, Employee Wellbeing, Net Promoter Score (NPS), and Workforce Analytics. Through live demonstrations, participants will witness firsthand how Zetscore empowers organizations to enhance Employee Engagement, Retention, Productivity, and Overall Positive Work Environment. Don't miss this opportunity to discover how ZetScore can transform your approach to performance evaluation and management",
        "eventLink": "https://meet.google.com/oqq-wjeq-jys",
        "eventStartTime": "10:00AM",
        "eventEndTime": "11:00AM",
        "eventDate": {
            "day": "Wednesday",
            "date": "25",
            "year": "2024",
            "month": "September",
            "timeZone": "GMT + 1"
        }
    },
    {
        "id": 2,
        "eventType": "In Person",
        "eventName": "ZetScore Showcase: Elevating Employee Performance",
        "eventDesc": "Join us for the ZetScore Showcase in Kigali (Rwanda) and Nairobi (Kenya) which will be taking place simultaneously. To explore cutting-edge solutions for enhancing Human Performace Management, Through live demonstrations and interactive sessions, discover the power of our comprehensive services. From Peer Review to Assessment Review, Employee Wellbeing Evaluation, Net Promoter Score (NPS), and Workforce Analytics to delve into strategies that drive workplace excellence. Engage with industry leaders, connect with peers, and uncover actionable insights to optimize talent management practices. Don't miss this opportunity to be at the forefront of digital transformation in workforce management.",
        "eventLink": null,
        "eventStartTime": "10:00AM",
        "eventEndTime": "11:00AM",
        "eventDate": {
            "day": "Friday",
            "date": "27",
            "year": "2024",
            "month": "September",
            "timeZone": "GMT + 1"
        }
    }
]