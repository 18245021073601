import React from 'react'

function DigitalTransformation() {
  return (
    <div class="d-flex flex-column h-100">
      <h3 class="a-services__name"> Digital Transformation </h3>
      <div class="a-services__description">
          Embark on a transformative journey with our Digital Transformation services. We specialize in redefining processes, optimizing structures, and harnessing technology to drive organizational evolution. 
          Whether it's enhancing operational efficiency or adapting to emerging trends, our comprehensive solutions ensure a seamless and forward-looking transformation for your business.
      </div>
      <div class="a-services__bullets d-flex flex-row flex-wrap justify-content-between">
        <div class="a-services__bullet">
          Customer Experience
        </div>
        <div class="a-services__bullet">
          Corporate Finance
        </div>
        <div class="a-services__bullet">
          Workforce
        </div>
        <div class="a-services__bullet">
          Enterprise-wide Transformation
        </div>
      </div>
      <div data-piwik-event="Editable: Services Editable: Software Development ,click,Explore further" class="a-services__link">
      
      </div>
    </div>
  )
}

export default DigitalTransformation
