import React from 'react'

function Design() {
  return (
    <div class="d-flex flex-column h-100">
      <h3 class="a-services__name">UI/UX Design</h3>
      <div class="a-services__description">
        User interface and user experience design for all types of websites, SaaS, and web/desktop apps. 
        We combine the latest UI/UX trends with our customers’ individual goals and needs to deliver intuitive, vibrant, and impactful designs that power up businesses.
      </div>

      <div class="a-services__bullets d-flex flex-row flex-wrap justify-content-between">
        <div class="a-services__bullet">
          User Interface (UI) Design
        </div>
        <div class="a-services__bullet">
          User Experience (UX) Design
        </div>
        <div class="a-services__bullet">
          Software as a Service (Saas) UI Design
        </div>
        <div class="a-services__bullet">
          Website Redesign
        </div>
       
      </div>
      <div data-piwik-event="Editable: Services Editable: Application Services,click,Explore further" class="a-services__link">
      
      </div>
    </div>
  )
}

export default Design
