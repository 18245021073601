import React from 'react'

function eGovernmentSolutions() {
  return (
    <div class="d-flex flex-column h-100">
      <h3 class="a-services__name">eGovernment Solutions </h3>
      <div class="a-services__description">
        With a history in eGovernment solutions, we specializes in delivering secure, reliable and user-friendly cutting-edge software solutions tailored for seamless integration with any governmental framework, 
        including G2C (Government to Citizen) and G2B (Government to Business) to meet the diverse needs of modern governance.
      </div>
      <div class="a-services__bullets d-flex flex-row flex-wrap justify-content-between">
        <div class="a-services__bullet">
          Civil Status Certificates (G2C)
        </div>
        <div class="a-services__bullet">
          Idendity Services (G2C)
        </div>
        <div class="a-services__bullet">
          Licensing and Permit (G2B)
        </div>
        <div class="a-services__bullet">
          Business Registration Portal (G2B)
        </div>
      </div>
      <div data-piwik-event="Editable: Services Editable: Software Development ,click,Explore further" class="a-services__link">
      
      </div>
    </div>
  )
}

export default eGovernmentSolutions
