import React from 'react'

function ConnectSphere() {
  return (
    <div class="d-flex flex-column h-100">
      <h3 class="a-services__name">ConnectSphere Service </h3>
      <div class="a-services__description">
        Introducing a holistic digital solution designed to elevate your business online presence. 
        Our expert team specializes in developing and maintaining websites, ensuring cutting-edge design and seamless functionality. 
        Additionally, we excel in fostering community engagement and growth through dedicated community management services. 
      </div>
      <div class="a-services__bullets d-flex flex-row flex-wrap justify-content-between">
        <div class="a-services__bullet">
          Website Development
        </div>
        <div class="a-services__bullet">
          Website Maintenance 
        </div>
        <div class="a-services__bullet">
          Community Management
        </div>
      </div>
      <div data-piwik-event="Editable: Services Editable: Software Development ,click,Explore further" class="a-services__link">
      
      </div>
    </div>
  )
}

export default ConnectSphere
