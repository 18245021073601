import React from 'react'

function Services() {
  return (
    <div class="d-flex flex-column h-100">
      <h3 class="hp-title">Explore Our Offering </h3>
      <div class="a-services__description">
          We offer a range of service go through them more in deep through a computer.
      </div>
      <div class="a-services__bullets d-flex flex-row flex-wrap justify-content-between">
        <div class="a-services__bullet">
          Software development 
        </div>
        <div class="a-services__bullet">
          eGovernment Solutions
        </div>
        <div class="a-services__bullet">
          UI/UX Design
        </div>
        <div class="a-services__bullet">
          Legacy Modernisation
        </div>
        <div class="a-services__bullet">
          ConnectSphere
        </div>
        <div class="a-services__bullet">
          Digital Transformation
        </div>
      </div>
    </div>
  )
}

export default Services
