import React from 'react'

function Software() {
  return (
    <div class="d-flex flex-column h-100">
      <h3 class="a-services__name">Software Development </h3>
      <div class="a-services__description">
        With three decades of combined team expertise, our software development company excels in creating reliable, scalable, and secure software solutions compatible with any operating system, browser, and device. 
        Our extensive industry expertise allows us to craft tailored solutions and products that seamlessly align with the unique needs and preferences of our users.
      </div>
      <div class="a-services__bullets d-flex flex-row flex-wrap justify-content-between">
        <div class="a-services__bullet">
          Custom software development
        </div>
        <div class="a-services__bullet">
          Software consulting
        </div>
        <div class="a-services__bullet">
          Software product development
        </div>
        <div class="a-services__bullet">
          Legacy software modernization
        </div>
      </div>
      <div data-piwik-event="Editable: Services Editable: Software Development ,click,Explore further" class="a-services__link">
      
      </div>
    </div>
  )
}

export default Software
