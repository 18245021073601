export const partnershipData = [
    {
        image:'https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/fixed-price-model-new.png',
        title:'Fixed Price Model',
        text:'In this model, the project scope of work with its associated cost and timeline is defined before development starts. This is a preferred model for longer periods of engagement. They always have peace of mind of knowing the project will remain on the same budget as agreed. This model suits best to our clients who have a perfect vision of their requirement.'
    },
    {
        image:'https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/hire-dedicated-model.png',
        title:'Hourly Engagement Model',
        text:'This is very classic and simple way of engagement wherein our client pay for the number of hours the team works on project. They easily start the project as they don\'t have to walk in with detailed specifications. This model also allows our clients to update new features any time and they know exactly what they’re paying for.'
    },
    {
        image:'https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/on-site-development-model-new.png',
        title:'Retainer-based Model',
        text:'This model is preferred when clients want to have guaranteed access to our company’s resources, such as time from consultants, developers, regular software updates or technical support. This helps to ensure that they  get priority service when needed. This model involves clients paying a recurring fee (monthly or quarterly) to retain our company\'s services'
    },{
        image:'https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/fixed-price-model-new.png',
        title:'Subscription-based Model',
        text:'This model allows clients to access our comprehensive suite of services, provided  by our solution which are ZetScore and Supply Chain Optimization as a Service (SCOS). The subscription includes regular updates, new features, and enhancements. Clients maintain continuous access to these services for the duration of their subscription.'
    },
    {
        image:'https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/hire-dedicated-model.png',
        title:'Support and Upkeep Model',
        text:'In this model, our company provides continuous support and maintenance services for our clients\' software or systems. This model ensures that clients\' technology remains functional, secure, and up-to-date through regular maintenance activities and technical support. This includes: Website Support or ongoing Software Support for custom applications build by us.'
    },
    {
        image:'https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/on-site-development-model-new.png',
        title:'On-Site Dedicated Model',
        text:'This model is ideal for clients seeking additional temporary or permanent resources for on-site development. This contract type ensures the engagement is cost-effective and a face-to-face interactions with developers. This model helps in achieving the deadline on time as there is continuous communication during the whole process.'
    },
   

]