export const jobData = [
   /* {
        "jobId": 1,
        "isScoping": true,
        "jobName": "Software Engineer",
        "empType": "openings",
        "isRemote": false,
        "jobLocation": "Cameroon (Remote), Rwanda (Remote)",
        "jobSummary": "The Software Engineer position involves having a good experience in both Frontend (JavaScript & Reactjs) and Backend (Java and Spring Boot) to contribute to the development and maintenance of software solutions. As a key member of our engineering team, you will be responsible for designing, coding, testing, and implementing scalable and high-performance applications. Your role will encompass the full software development life cycle, from requirements analysis to deployment.",
        "jobResponsibility": [
            "Design and develop robust, efficient, and maintainable software solutions using Reactjs, Java and Spring Boot frameworks.",
            "Collaborate with cross-functional teams, including product managers and other developers, to understand project requirements and translate them into technical specifications.",
            "Conduct code reviews to ensure code quality, best practices, and adherence to coding standards.",
            "Troubleshoot, debug, and optimize existing systems to enhance performance and address issues.",
            "Mentor and guide Intern developers, fostering a collaborative and knowledge-sharing environment within the team.",
            "Participate in Agile Development methodologies, including sprint planning, stand-ups, and retrospectives."
        ],
        "jobQualifications": [
            "Bachelor's or Master's degree in Computer Science or a related field.",
            "Proven experience as a Software Engineer with a strong focus on ReactJs and Spring Boot development.",
            "Solid understanding of software architecture, design principles, and best practices.",
            "Proficiency in database design and integration, with experience in SQL and NoSQL databases.",
            "Strong analytical and problem-solving skills, coupled with excellent communication and collaboration abilities.",
            "Familiarity with agile development methodologies and tools.",
            "Very strong communication skills and love working into startups ecosystem."
        ],
        "jobEligibility": [
            "Minimum of 3 years of hands-on experience in ReactJs and Spring Boot development.",
            "Proven track record of successfully delivering complex software projects from inception to completion.",
            "The position is available to bilingual speakers in both French and English, with preference given to candidates from Cameroon or Rwanda."
        ]
    }*/
]

export const internshipData = [
    {
        "jobId": 2,
        "isScoping": true,
        "jobName": "Junior UX/UI Designer",
        "empType": "openings",
        "isRemote": true,
        "jobLocation": "",
        "jobSummary": "As a Junior UX/UI Designer, you will collaborate with our design team to create engaging (UI) and intuitive user experiences (UX). Your responsibilities will include wireframing, prototyping, and conducting user research to inform design decisions. You will assist in translating user needs and business requirements into visually appealing interfaces across web, desktop and mobile platforms. This role offers an exciting opportunity to grow your skills in a dynamic and supportive environment, contributing to the creation of innovative digital products.",
        "jobResponsibility": [
            "Collaborate with cross-functional teams to define user requirements and design solutions that align with project goals.",
            "Create visually appealing user interfaces by applying principles of design, typography, and color theory.",
            "Develop wireframes, mockups, and prototypes to communicate design concepts and facilitate feedback from stakeholders.",
            "Iterate on designs based on feedback, user testing results, and usability best practices to continuously improve the user experience.",
            "Participate in agile development methodologies, including sprint planning, stand-ups, and retrospectives."
        ],
        "jobQualifications": [
            "Associate or HND or Bachelor's degree in Computer Science or a related field.",
            "Basic knowledge in design software such as Figma, Adobe Creative Suite (Adobe XD, Photoshop, Illustrator) or Sketch as preferred qualification.",
            "Understanding of user-centered design principles .",
            "Excellent communication and collaboration skills to work effectively in a cross-functional team environment.",
            "Ability to adapt to evolving design requirements and prioritize tasks in a fast-paced, dynamic environment."
        ],
        "jobEligibility": [
            "The position is open to English speakers with preference bilinguals French and English speakers who are from any African country (Remote)."
        ]
    },
    /*{
        "jobId": 3,
        "isScoping": true,
        "jobName": "Product Designer",
        "empType": "openings",
        "isRemote": false,
        "jobLocation": "Kenya (Remote), Rwanda (Remote), Cameroon (Remote)",
        "jobSummary": "As a Product Designer, you'll craft intuitive and visually compelling user experiences, collaborating closely with cross-functional teams. Through research, prototyping, and user feedback, you'll refine designs that align with both user needs and business objectives. Your role involves translating intricate concepts into seamless interfaces, ensuring our products resonate with users. Join us in shaping the future of digital experiences with your creative and strategic design expertise.",
        "jobResponsibility": [
            "Collaborate with product managers and cross-functional teams to define product requirements and user stories.",
            "Create wireframes, user flows, and prototypes to visualize and communicate design concepts.",
            "Design intuitive and elegant user interfaces that align with brand guidelines and enhance the overall user experience.",
            "Work closely with developers to ensure seamless implementation of designs and maintain design consistency throughout the product.",
            "Collaborate with marketing and sales teams to create compelling visual assets and support product launch activities."
        ],
        "jobQualifications": [
            "Bachelor's degree in design, human-computer interaction, or related field, or equivalent practical experience",
            "Proven experience as a product designer, with a portfolio showcasing user-centric design solutions.",
            "Proficiency in design tools such as Adobe Creative Suite, Sketch, Figma, or similar software.",
            "Solid understanding of user experience design principles, usability best practices, and interaction design concepts",
            "Ability to translate complex user requirements into intuitive and visually appealing interface designs.",
            "Attention to detail and a keen eye for typography, layout, and visual hierarchy.",
            "Strong communication skills with the ability to present and defend design decisions to stakeholders."
        ],
        "jobEligibility": [
            "Minimum of 1 year of experience in a similar role",
            "The position is open to English speakers with preference to bilinguals French and English speakers who are from Cameroon or Rwanda (All Remote).",
        ]
    }*/
]


export const consultantData = [
   /* {
        "jobId": 3,
        "isScoping": true,
        "jobName": "Frontend Developer",
        "empType": "openings",
        "isRemote": false,
        "jobLocation": "Kenya (Remote), Rwanda (Remote)",
        "jobSummary": "As a Frontend Developer, you will play a pivotal role in the development and enhancement of one of our solutions, ensuring an intuitive and seamless user experience. Leveraging your expertise in JavaScript, TypeScript, ReactJs and Tailwind CSS, you will collaborate closely with our design and backend teams to translate requirements into efficient and visually appealing frontend solutions. Your responsibilities will include crafting reusable components, optimizing application performance, and implementing responsive designs to cater to various devices and screen sizes.",
        "jobResponsibility": [
            "Develop and complete frontend components using JavaScript, TypeScript, ReactJs and Tailwind CSS.",
            "Identify and address technical debt and areas for improvement in the frontend architecture.",
            "Troubleshoot and debug frontend issues, ensuring timely resolution.",
            "Work closely with Lead Frontend Developer and CTO to understand requirements and translate them into technical solutions.",
            "Design intuitive and elegant user interfaces that align with brand guidelines and enhance the overall user experience."
        ],
        "jobQualifications": [
            "Bachelor's degree in Computer Science, Engineering, or related field, or equivalent practical experience",
            "Proven experience as a frontend engineer, with a portfolio showcasing previous solutions.",
            "Proficiency in JavaScript, TypeScript, and React.js.",
            "Strong understanding of frontend development principles, including responsive design, cross-browser compatibility, and accessibility.",
            "Solid understanding of RESTful APIs and asynchronous programming.",
            "Ability to translate complex user requirements into intuitive and visually appealing interface designs.",
            "Attention to detail and a keen eye for typography, layout, and visual hierarchy.",
            "Strong communication skills with the ability to present and defend design decisions to stakeholders."
            
        ],
        "jobEligibility": [
            "Minimum of 3+ year of experience in a similar role",
            "The position is open to English speakers with preference to bilinguals French and English speakers who are legally authorized to work in Kenya or Rwanda (Remote).",
        ]
    }*/
   
]