import OutsourcingImg from '../../resources/images/blog/outsourcing-services.png';
import PronsAgileImg from '../../resources/images/blog/prons-agile.png';
import MvpVSPrototypeImg from '../../resources/images/blog/mvp-vs-prototype.png';
import StockOptimisation from  '../../resources/images/blog/Stock_Optimisation_Header.png';
import Persona from "../../resources/images/IZ.svg";

export const blogData = [
    {
        "id": 1,
        "blogUrl": "software-outsourcing-services",
        "title": "Software Outsourcing Services and Reasons to Choose Them",
        "category": "Software Development",
        "image": OutsourcingImg,
        "content": "Why to choose Software Outsourcing Services and how to find a good outsourcing partner.",
        "author": "By Beryl",
        "date": "Oct 02, 2023"
    },
    {
        "id": 2,
        "blogUrl": "outsourced-software-why",
        "title": "Why Agile is Great for Outsourced Software Projects",
        "category": "Software Development",
        "image": PronsAgileImg,
        "content": "The Agile approach to software development outsourcing has big benefits for vendors in terms of easier project planning.",
        "author": "By Yanick",
        "date": "Nov 06, 2013"
    },
    {
        "id": 3,
        "blogUrl": "five-stock-optimisation-techniques",
        "title": "Five Stock Optimization Techniques",
        "category": "Supply Chain Optimization",
        "image": StockOptimisation,
        "content": "The goal of stock optimization is to ensure the right products are in the right place at the right time, as efficiently and cost-effectively as possible.",
        "author": "By IZSoftwares",
        "date": "June 01, 2024"
    }
]

export const blogDataDetail = [
    {
        "id": 1,
        "blogUrl": "software-outsourcing-services",
        "title": "Software Outsourcing Services and Reasons to Choose Them",
        "userProfile": Persona,
        "author": "Beryl Joceran",
        "position": "Chief Technology Officer",
        "company": "IZSoftwares",
        "category": "Software Development",
        "publishedDate": "Published October 02, 2023",
        "timeframe": 5,
        "image": OutsourcingImg,
        "content": "Why to choose Software Outsourcing Services and how to find a good outsourcing partner.",
        "overviewContent": "Software outsourcing services involve entrusting specific software projects to a seasoned IT/Software company. \
                            Rather than managing an internal team, businesses are progressively opting to assign their IT operations and duties—such as software development and upkeep, legacy modernization, ui/ux design, quality assurance, and more—to an external software outsourcing vendor. \
                            In essence, instead of handling all software-related tasks internally, companies are leveraging the expertise and resources of external service providers to efficiently manage and execute various aspects of their software projects. \
                            This approach allows businesses to focus on their core competencies while benefiting from the specialized skills and efficiency of external software outsourcing vendors.",
        "keyFeatures": [],
        "keyBenefits": [
            {
            "subtitle": "Cost Savings",
            "description": "Smaller cost as compared to hiring an in-house Software Team is the biggest motivation for outsourcing Software services. \
                            By outsourcing, you can save on salaries, social benefits, onboarding and training, office space, and other costs associated with having in-house IT experts."
            },
            {
            "subtitle": "Full Focus on Business Development",
            "description": "The practice of outsourcing Softwares services enables your company to concentrate on its daily operations and strategic goals. By assigning IT-related responsibilities to external specialists, you can allocate your internal resources and efforts to tasks that directly enhance your business efficiency."
            },
            {
            "subtitle": "Access to diverse expertise",
            "description": "Leveraging software outsourcing enables you to access a reservoir of IT professionals proficient in diverse areas and specialized technologies."
            },
            {
            "subtitle": "Flexibility",
            "description": "Whether your business encounters seasonal variations or is in the midst of expansion plans, outsourcing provides the flexibility to adjust IT services, either scaling them up or down, to align with your present business requirements."
            },
        ],
        "keyReason": [],
        "challengeWeSolve": {
            "content": "At IZSoftwares, we successfully solve the following business challenges on software outsourcing:",
            "items": [
                "Staff Augmentation.",
                "Legacy Modernization.",
                "Prototype Development",
                "MVP Development (less than 4 months)"
            ]
        }
    },
    {
        "id": 2,
        "blogUrl": "outsourced-software-why",
        "title": "Why Agile is Great for Outsourced Software Projects",
        "userProfile": Persona,
        "author": "Nde Yanick",
        "position": "VP of Engineering & Technology",
        "company": "IZSoftwares",
        "category": "Software Development",
        "publishedDate": "Published November 06, 2023",
        "timeframe": 5,
        "image": PronsAgileImg,
        "content": "The Agile approach to software development outsourcing has big benefits for vendors in terms of easier project planning.",
        "overviewContent": "Software outsourcing services involve entrusting specific software projects to a seasoned IT/Software company. \
                            Rather than managing an internal team, businesses are progressively opting to assign their IT operations and duties—such as software development and upkeep, legacy modernization, ui/ux design, quality assurance, and more—to an external software outsourcing vendor. \
                            In essence, instead of handling all software-related tasks internally, companies are leveraging the expertise and resources of external service providers to efficiently manage and execute various aspects of their software projects. \
                            This approach allows businesses to focus on their core competencies while benefiting from the specialized skills and efficiency of external software outsourcing vendors.",
        "keyFeatures": [],
        "keyBenefits": [
            {
            "subtitle": "Cost Savings",
            "description": "Smaller cost as compared to hiring an in-house Software Team is the biggest motivation for outsourcing Software services. \
                            By outsourcing, you can save on salaries, social benefits, onboarding and training, office space, and other costs associated with having in-house IT experts."
            },
            {
            "subtitle": "Full Focus on Business Development",
            "description": "The practice of outsourcing Softwares services enables your company to concentrate on its daily operations and strategic goals. By assigning IT-related responsibilities to external specialists, you can allocate your internal resources and efforts to tasks that directly enhance your business efficiency."
            },
            {
            "subtitle": "Access to diverse expertise",
            "description": "Leveraging software outsourcing enables you to access a reservoir of IT professionals proficient in diverse areas and specialized technologies."
            },
            {
            "subtitle": "Flexibility",
            "description": "Whether your business encounters seasonal variations or is in the midst of expansion plans, outsourcing provides the flexibility to adjust IT services, either scaling them up or down, to align with your present business requirements."
            },
        ],
        "keyReason": [],
        "challengeWeSolve": {
            "content": "At IZSoftwares, we successfully solve the following business challenges on software outsourcing:",
            "items": [
                "Staff Augmentation.",
                "Legacy Modernization.",
                "Prototype Development",
                "MVP Development (less than 4 months)"
            ]
        }
    },
    {
        "id": 3,
        "blogUrl": "five-stock-optimisation-techniques",
        "title": "Five Stock Optimization Techniques",
        "category": "Supply Chain Optimization",
        "userProfile": Persona,
        "author": "IZSoftwares",
        "position": "Content Writer Team",
        "company": "IZSoftwares",
        "publishedDate": "Published June 01, 2024",
        "timeframe": 25,
        "image": StockOptimisation,
        "content": "The goal of stock optimization is to ensure the right products are in the right place at the right time, as efficiently and cost-effectively as possible.",
        "overviewContent": "The goal of stock optimization is to ensure the right products are in the right place at the right time, as efficiently and cost-effectively as possible.\
                            Stock Optimization (also known as inventory optimization), it is crucial for the effective implementation of inventory control techniques. \
                            This process involves balancing stock availability with reduced inventory costs and minimizing excess items by forecasting demand, managing supply variables, and dynamically adjusting stock rules and inventory parameters.\
                            In this blog we’ll look at 5 inventory control key features that will specifically show you how to control your stock levels, help you optimize your inventory and maximize profits.",
        "keyFeatures": [
            {
                "subtitle": "Know your star products (ABC Analysis)",
                "description": "ABC analysis is an effective inventory control technique for segmenting warehouse stock based on its value to the company.\
                                Each item in your warehouse holds different value in terms of the revenue it generates for the business.\
                                Value can be defined in various ways, such as sales revenue, profitability, sales volume, or annual consumption value. \
                                Choose a definition that suits your needs and then categorize your stock into A, B, and C groups. 'A' items are critical to your business's success, while 'C' items are the least important."
                },
                {
                "subtitle": "Set stock levels control policies",
                "description": "Inventory policies ensure you’re stocking the right goods in the right quantities, which is essential for effective warehouse inventory control. Establish specific ‘rules’ for every Stock Keeping Unit (SKU) you carry.\
                                Inventory classification methods, such as ABC analysis, can assist with this by setting different service levels, safety stock levels, and reordering parameters for each category. \
                                It’s also important to have a policy for reducing excess stock and removing obsolete items. Excess stock negatively impacts stock turnover and ties up working capital, and if it becomes obsolete, it will also erode profit margins.\
                                Additionally, setting useful inventory KPIs is crucial. Review your current KPIs to ensure they are adding value to your operations by meeting business objectives and enhancing efficiency, customer service, and profitability."
                },
                {
                "subtitle": "Establish service level targets to improve stock optimization",
                "description": "A target service level measures the likelihood of having the correct quantity of an item in stock when it's requested for delivery, ensuring a completely fulfilled order.\
                                When setting target service levels, take into account your customers' expectations for availability and delivery times.\
                                For instance, if a nine-day lead time is acceptable to your customers, you might be able to lower your inventory levels and rely on smaller purchase quantities, thereby reducing tied-up capital.\
                                Alternatively, you could place on-demand orders with your suppliers if they have short lead times. Remember, providing higher service levels than necessary costs your company money.\
                                However, failing to meet customer expectations can lead to lost sales and a damaged reputation. Finding a balance is crucial. Service levels will also affect your stock turnover rate.\
                                Aim for higher service levels on faster-moving items and lower them for items with less demand. This approach helps maintain a high turnover rate and avoids unnecessarily tying up capital."
                },
                {
                "subtitle": "Fine-tune your stock replenishment",
                "description": "Firstly, you can only optimize stock levels with informed inventory purchasing practices. Most businesses reorder either on a fixed schedule or when stock drops to a specified level, known as the reorder point.\
                                The reorder quantity is usually either a fixed amount or variable to meet a minimum or maximum stock capacity (MSC). Many ERP (Enterprise Resource Planning) and WMS (Warehouse Management System) use these approaches.\
                                However, if these methods result in stockouts or excess stock, a smarter replenishment strategy is needed. A more informed or dynamic approach involves considering the following variables: Demande forecast, Supplier lead times and Cost-effective order quantities."
                },
                {
                    "subtitle": "Carry safety stock to reduce risk of stock outs",
                    "description": "Safety stock, also known as buffer stock, is the inventory kept to prevent stockouts and back orders when forecasts are exceeded or supply is delayed. It helps minimize disruptions caused by demand fluctuations, supply chain issues, or fulfillment delays, while keeping inventory investment to a minimum.\
                                    Many businesses, including those using WMS and ERP systems, still rely on a basic stock days model to calculate safety stock. This involves determining the number of days (or weeks) of demand and adding enough buffer stock to cover any variance. For instance, they might use 4 weeks of cycle stock and 2 weeks of safety stock.\
                                    However, this “one size fits all” approach assumes that all goods in the warehouse have similar demand patterns, which is not the case. More accurate safety stock calculations can help avoid out-of-stock or overstock situations.\
                                    The most important factors to consider when calculating safety stock are: The desired service level, Forecasting accuracy and Lead time."
                },
        ],
        "keyBenefits": [],
        "keyReason": [],
        "challengeWeSolve": {
            "content": "At IZSoftwares, Optimizing your stock levels necessitates consideration of supply and demand variables. Effective inventory control techniques thus rely on employing tactics for stock optimization.\
                        While many businesses utilize standard ERP or WMS systems, or even resort to spreadsheets, these methods have inherent limitations in functionality. Consequently, an increasing number of businesses are turning to inventory optimization software to automate processes and enhance efficiency.\
                        These tools leverage advanced algorithms to deliver accuracy and speed to inventory management calculations, unavailable elsewhere.\
                        What we solve as main challenges when using our services are:",
            "items": [
                "Limitations of Traditional Inventory Management Systems",
                "Cost Barriers for Small to Medium-Sized Businesses",
                "Time-Consuming Manual Processes",
                "Lack of Real-Time Data"
            ]
        }
    }
]