import React from 'react'

function LegacyModernisation() {
  return (
    <div>
      <div class="d-flex flex-column h-100">
        <h3 class="a-services__name"> Legacy Modernisation</h3>
        <div class="a-services__description">
          <p> 
            Unlock your business's full potential with Legacy Modernization services. Seamlessly transition outdated systems to cutting-edge technologies, ensuring agility, scalability, and security. 
            Our expert team revitalizes your applications, enhancing user experience, and aligning with industry standards.
          </p>
        </div>
        <div class="a-services__bullets d-flex flex-row flex-wrap justify-content-between">
          <div class="a-services__bullet">
            Architectural Modernization
          </div>
          <div class="a-services__bullet">
            Frontend Modernization
          </div>
          <div class="a-services__bullet">
            Code Modernisation
          </div>
          <div class="a-services__bullet">
            Technology Migration
          </div>
        </div>
        <div data-piwik-event="Editable: Services Editable: Testing &amp; QA,click,Explore further" class="a-services__link">
        
        </div>
      </div>
    </div>
  )
}

export default LegacyModernisation


